import React from 'react';
import CustomContainer from '../../CustomContainer';
import backgroundImage from '../../../images/homepage/banner-surat-pendiri.png';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

const SectionSuratPendiri = () => {
  const { t } = useTranslation('homepage');

  return (
    <div className="h-full md:h-[590px] bg-black relative">
      <CustomContainer className="md:h-full 2xl:mx-0">
        <div className="py-16 md:py-0 w-full md:w-[35%] h-full flex flex-col justify-start md:justify-center">
          <h2 className=" text-3xl md:text-4xl font-medium">
            {t('suratDariPendiri')}
          </h2>
          <p className="text-sm font-[200] mt-5 leading-relaxed">
            {t('industriMedia')}
          </p>
          <Link to="/about-us">
            <div className="text-center bg-folk-orange w-full md:w-48 py-3 px-14 rounded-3xl text-[14px] mt-10 hover:opacity-70">
              Learn More
            </div>
          </Link>
        </div>
      </CustomContainer>
      <div className="relative md:absolute right-0 w-full md:w-[60%] top-0 h-1/2 md:h-full">
        <img
          src={backgroundImage}
          alt="background"
          className="h-full object-cover w-full"
        />
      </div>
    </div>
  );
};

export default SectionSuratPendiri;

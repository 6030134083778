import React from 'react';
import backgroundImage from '../../../images/homepage/banner-homepage.png';
import arrowDownIc from '../../../images/icon/arrow-bottom-ic.svg';
import CustomContainer from '../../CustomContainer';
import folkLogoHalf from '../../../images/icon/logo/folk-logo-half-orange.svg';
import { useTranslation } from 'react-i18next';

const SectionBanner = ({ bottomRef }) => {
  const scrollDown = () => {
    if (bottomRef.current) {
      window.scrollTo({
        top: bottomRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const { t } = useTranslation('homepage');

  return (
    <div className="h-screen relative bg-folk-black">
      <div className="absolute bottom-0 right-0 xl:w-[500px] md:w-[400px] w-[230px] flex items-end justify-end z-10">
        <img src={folkLogoHalf} className="object-cover max-h-full " />
      </div>
      <div className="absolute h-full w-full top-0 left-0">
        <img
          src={backgroundImage}
          alt="background"
          className="object-cover h-full w-full"
        />
      </div>
      <CustomContainer className="relative">
        <div className="pt-20 h-screen">
          <div className="h-full flex flex-col justify-center">
            <h1 className="text-4xl md:text-6xl font-medium sm:mb-3 z-10">
              {t('cultivating')}
            </h1>
            <h1 className="text-4xl md:text-6xl font-medium mb-3 sm:mb-5 z-10">
              {t('theNextGen')}
            </h1>
            <p className="text-sm md:text-base w-[100%] sm:w-[70%] lg:w-[45%] font-extralight mb-10 z-10">
              {t('folkBergerak')}
            </p>
            <img
              onClick={scrollDown}
              src={arrowDownIc}
              alt="icon-arrow"
              className="cursor-pointer hidden md:block z-10 w-[25px] cursor-pointer"
            />
          </div>
        </div>
      </CustomContainer>
    </div>
  );
};

export default SectionBanner;

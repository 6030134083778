import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import Pages from '../components/Pages';
import SectionBanner from '../components/pages/Homepage/SectionBanner';
import SectionLearnMore from '../components/pages/Homepage/SectionLearnMore';
import SectionSuratPendiri from '../components/pages/Homepage/SectionSuratPendiri';
import SectionTigaPilar from '../components/pages/Homepage/SectionTigaPilar';

const Homepage = () => {
  const toBottomRef = useRef(null);
  return (
    <div className="min-h-screen text-white">
      <Pages>
        <SectionBanner bottomRef={toBottomRef} />
        <SectionTigaPilar
          bottomRef={toBottomRef}
          containerClassName="pt-32 pb-20"
        />
        <SectionLearnMore />
        <SectionSuratPendiri />
      </Pages>
    </div>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Homepage;

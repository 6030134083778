import React from 'react';
import CustomContainer from '../../CustomContainer';
import backgroundImage from '../../../images/homepage/banner-learn-more.png';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

const SectionLearnMore = () => {
  const { t } = useTranslation('homepage');

  return (
    <div className="h-[85vh] bg-black relative">
      <CustomContainer>
        <div className="py-10 lg:py-20">
          <h1 className="text-xl md:text-4xl font-medium leading-snug md:leading-tight mb-10 md:mb-20">
            {t('kombinasiSeluruhEkosistem')}
          </h1>
          <Link to="/about-us">
            <div className="text-center w-full sm:w-48 border py-3 px-14 rounded-3xl text-[14px] hover:opacity-70">
              Learn More
            </div>
          </Link>
        </div>
        <div className="absolute h-80 md:w-screen bottom-0 left-0">
          <img
            src={backgroundImage}
            alt="background"
            className="w-full h-full object-cover"
          />
        </div>
      </CustomContainer>
    </div>
  );
};

export default SectionLearnMore;
